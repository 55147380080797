import React from 'react';

import {Cron} from 'react-js-cron';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import {TData} from '../../CrudPage';
import {CrudCronProps} from './CrudCron.d';
import {PTBR_CRON_LOCALE} from './locale';

import getNestedValue from '../../../utils/getNestedValue';
import setNestedValue from '../../../utils/setNestedValue';
import hasNestedValue from '../../../utils/hasNestedValue';


const CrudCron = <T extends TData>(props: CrudCronProps<T>) => {

    const {
        column,
        values,
        setValues,
        errors,
        readOnly = false,
        required = false,
    } = props;

    const {
        accessorKey,
        header,
    } = column;

    const inputHasError = hasNestedValue(errors, accessorKey);

    return (
        <FormControl
            fullWidth
            key={accessorKey}
        >
            <InputLabel
                id={accessorKey}
                shrink={true}
                required={required}
                sx={{
                    backgroundColor: 'white',
                    pr: 1,
                }}
            >
                {header}
            </InputLabel>
            <Box
                border={1}
                borderRadius={1}
                borderColor={inputHasError ? 'error.main' : 'grey.400'}
                padding={2}
                paddingBottom={1}
            >
                <Cron
                    locale={PTBR_CRON_LOCALE}
                    value={getNestedValue(values, accessorKey, '')}
                    setValue={(value: string) => {
                        setValues(
                            setNestedValue(values, accessorKey, value),
                        );
                    }}
                    readOnly={readOnly}
                    displayError={inputHasError}
                />
            </Box>
            {inputHasError &&
                <FormHelperText>{getNestedValue(errors, accessorKey, '')}</FormHelperText>
            }
        </FormControl>
    );
};

export default CrudCron;