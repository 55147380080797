import React, {useState} from 'react';
import {useSnackbar} from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import ConnectionIcon from '@mui/icons-material/Cable';

import api from '../../../services/api';
import AppRoutes from '../../../routes/appRoutes';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CrudAPI, {Column} from '../../../components/crud/CrudPage';
import {Database} from './index';


const columns: Column<Database>[] = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'driver.name',
        header: 'Driver',
        field: {
            type: 'search',
            name: 'driver',
            endpoint: '/api/v0/config/databases/drivers/',
            labelKey: 'name',
        },
    },
    {
        accessorKey: 'host',
        header: 'Host',
        hideColumn: true,
    },
    {
        accessorKey: 'port',
        header: 'Porta',
        hideColumn: true,
    },
    {
        accessorKey: 'name',
        header: 'Nome',
    },
    {
        accessorKey: 'user',
        header: 'Usuário',
        hideColumn: true,
    },
    {
        accessorKey: 'password',
        header: 'Senha',
        hideColumn: true,
        field:{
            type: 'password',
        },
    },
] as Column<Database>[];


const name = {
    singular: 'Banco de Dados',
    plural: 'Bancos de Dados',
};


const DatabasesPage = () => {

    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const testConnection = (values: any) => {
        setLoading(true);
        api.get(`/api/v0/databases/${values.id}/test/`).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Conexão realizada com sucesso!', {variant: 'success'});
            }
        }).catch((err) => {
            enqueueSnackbar('Erro ao realizar conexão!', {variant: 'error'});
        }).finally(() => {
            setLoading(false);
        });
    };

    const renderModalActions = (values: any, unsavedChanges: boolean) => {
        if (values.id) {
            return (
                <Tooltip
                    title={unsavedChanges ? 'Salve as alterações antes de testar a conexão' : 'Testar conexão'}
                    placement={'top'}
                >
                    <span>
                        <Button
                            variant={'outlined'}
                            onClick={() => testConnection(values)}
                            disabled={loading || unsavedChanges}
                        >
                            {loading ?
                                <CircularProgress size={22} sx={{mr: 1}}/> :
                                <ConnectionIcon sx={{mr: 1}}/>
                            }
                            Testar
                        </Button>
                    </span>
                </Tooltip>);
        }

        return null;
    };

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{pt: 4}}
            >
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Mensageria', name.singular]}/>
                </Box>
            </Stack>

            <CrudAPI
                name={name}
                route={AppRoutes.Databases}
                endpoint={'/api/v0/databases/'}
                columns={columns}
                extraModalActions={renderModalActions}
            />

        </>
    );
};

export default DatabasesPage;