import React, {Fragment} from 'react';

import CrudApiSelect, {CrudApiSelectProps} from '../fields/CrudApiSelect';
import CrudCheckBox, {CrudCheckBoxProps} from '../fields/CrudCheckBox';
import CrudCron, {CrudCronProps} from '../fields/CrudCron';
import CrudDatePicker, {CrudDatePickerProps} from '../fields/CrudDatePicker';
import {CrudFormProps} from '../CrudForm';
import CrudHeader, {CrudHeaderProps} from '../fields/CrudHeader';
import {TData} from '../CrudPage';
import CrudSelect, {CrudSelectProps} from '../fields/CrudSelect';
import CrudText, {CrudTextProps} from '../fields/CrudText';


const CrudForm = <T extends TData>(props: CrudFormProps<T>) => {

    const {
        values,
        setValues,
        errors,
        readOnly,
        columns,
    } = props;

    return (
        <Fragment>
            {columns.map((column) => {

                const inputProps = {
                    key: column['accessorKey'],
                    values: values,
                    setValues: setValues,
                    errors: errors,
                    readOnly: readOnly,
                    column: column,
                };

                if (!column.enableEditing) {
                    return null;
                }

                switch (column.field?.type) {
                    case 'header':
                        return (<CrudHeader {...(inputProps as CrudHeaderProps<T>)}/>);
                    case 'search':
                        return (<CrudApiSelect {...(inputProps as CrudApiSelectProps<T>)}/>);
                    case 'date':
                        return (<CrudDatePicker {...inputProps as CrudDatePickerProps<T>}/>);
                    case 'select':
                        return (<CrudSelect {...(inputProps as CrudSelectProps<T>)}/>);
                    case 'check':
                        return <CrudCheckBox {...inputProps as CrudCheckBoxProps<T>}/>;
                    case 'cron':
                        return (<CrudCron {...(inputProps as CrudCronProps<T>)}/>);
                    case 'custom':
                        return column.field.component(values, setValues, errors, readOnly);
                    default:
                        return (<CrudText {...inputProps as CrudTextProps<T>}/>);
                }
            })}
        </Fragment>
    );
};

export default CrudForm;