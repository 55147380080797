import React, {Dispatch, SetStateAction} from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import {TemplateFromParameters} from '../Template.d';


const ParametersField = (
    values: TemplateFromParameters,
    setValues: Dispatch<SetStateAction<TemplateFromParameters>>,
    errors: Record<string, string>,
    readOnly: (e: TemplateFromParameters) => boolean,
) => {
    if (values.type !== 'templatefromparameters') {
        return null;
    }

    const handleAddParameter = () => {
        setValues({
            ...values,
            parameters: [
                ...values?.parameters ?? [],
                {
                    id: 0,
                    name: '',
                }
            ],
        });
    }

    const handleRemoveParameter = (index: number) => {
        const newParameters = [...values?.parameters];
        newParameters.splice(index, 1);
        setValues({
            ...values,
            parameters: newParameters,
        });
    }

    const handleUpdateParameter = (index: number, value: string) => {
        const newParameters = [...values?.parameters];
        newParameters[index].name = value;
        setValues({
            ...values,
            parameters: newParameters,
        });
    }

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack
            key={'parameters'}
            direction={'column'}
            spacing={2}
            sx={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: 2,
            }}
        >
            {values.parameters.map((parameter, index) => (
                <TextField
                    key={`parameter_${index}`}
                    label={`Parâmetro ${index + 1}`}
                    variant={'outlined'}
                    value={parameter.name}
                    onChange={(e) => handleUpdateParameter(index, e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                <IconButton onClick={() => handleRemoveParameter(index)}>
                                    <RemoveIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ))}

            <Button
                variant={'outlined'}
                color={'primary'}
                startIcon={<AddIcon/>}
                onClick={handleAddParameter}
                children={'Adicionar Parâmetro'}
            />
        </Stack>
    );
};

export default ParametersField;
