import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';

import AppRoutes from './appRoutes/appRoutes';
import PrivatePages from './privatePages';

// components
import RouteWithSidebar from './components/RouteWithSidebar/RouteWithSidebar';
import RouteWithTenant from './components/RouteWithTenant/RouteWithTenant';
import RouteOfSystem from './components/RouteOfSystem';
import Preloader from '../components/Preloader';

// service
import {getCachedSuperUser, getPermissions} from '../services/storage/permissions';

// pages
import Blank from '../pages/Blank';
import NotFound from '../pages/errors/NotFound';
import Settings from '../pages/user/Settings';


function PrivateAppRoutes() {

    const [loading, setLoading] = useState(true);

    /**
     * LOAD PERMISSIONS
     */
    const [permissions, setPermissions] = useState([]);
    const [superuser, setSuperuser] = useState(false);

    useEffect(() => {
        getPermissions().then((permissions) => {
            setPermissions(permissions ?? []);
            setSuperuser(getCachedSuperUser() ?? false);
            setTimeout(() => setLoading(false), 1000);
        });
    }, []);

    return (<>
        <Preloader show={loading}/>

        <Routes>

            {/* user */}
            <Route path={AppRoutes.UserSettings.path} element={<RouteWithSidebar component={Settings}/>}/>

            {PrivatePages.map((page, index) => {
                return (
                    <Route
                        key={index}
                        path={page.route.path}
                        element={
                            <RouteOfSystem
                                page={page}
                                superuser={superuser}
                                permissions={permissions}
                            />
                        }
                    />
                );
            })}

            {/* redirect */}
            <Route path={'/'} element={<RouteWithSidebar component={Blank}/>}/>

            {/* error pages */}
            <Route path={'*'} element={<NotFound/>}/>

        </Routes>
    </>);
}

export default function PrivateRoutes() {
    return (
        <RouteWithTenant>
            <PrivateAppRoutes/>
        </RouteWithTenant>
    );
}