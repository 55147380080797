import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {CrudPasswordProps} from '../CrudPassword';
import {TData} from '../../CrudPage';
import CrudText from '../CrudText';

const CrudPassword = <T extends TData>(props: CrudPasswordProps<T>) => {

    const {
        column,
        values,
        setValues,
        errors,
        readOnly,
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleMouseDown = (event: MouseEvent) => {
        event.preventDefault();
        setShowPassword(true);
    };

    const handleMouseUp = (event: MouseEvent) => {
        event.preventDefault();
        setShowPassword(false);
    };

    // @ts-ignore
    return (<CrudText
        column={{
            ...column,
            field: {
                InputProps: {
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <IconButton
                                // onMouseUp={handleMouseUp}
                                // onMouseDown={handleMouseDown}
                                children={showPassword ? <Visibility/> : <VisibilityOff/>}
                            />
                        </InputAdornment>
                    ),
                },
                type: showPassword ? 'text' : 'password',
            },
        }}
        values={values}
        setValues={setValues}
        errors={errors}
        readOnly={readOnly}
    />);

};

export default CrudPassword;