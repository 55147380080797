import React, {Dispatch, SetStateAction} from 'react';

import CrudText from '../../../../components/crud/fields/CrudText';

import {NexChatInstance} from '../Instance.d';


const ChannelIdField = (
    values: NexChatInstance,
    setValues: Dispatch<SetStateAction<NexChatInstance>>,
    errors: Record<string, string>,
    readOnly: (e: NexChatInstance) => boolean,
) => {

    if (values?.type !== 'instancenexchat') {
        return null;
    }

    return (
        <CrudText
            key={'channel_id'}
            column={{
                accessorKey: 'channel_id',
                header: 'ID do Canal',
            }}
            values={values}
            setValues={setValues}
            errors={errors}
        />
    );
};

export default ChannelIdField;
