import React, {useMemo, useState} from 'react';
import {enqueueSnackbar} from 'notistack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PasswordIcon from '@mui/icons-material/Password';

import api from '../../../services/api';
import appRoutes from '../../../routes/appRoutes';

import {User} from '../../../services/storage/user';
import {hasPermission} from '../../../services/storage/permissions';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CrudPage, {Column} from '../../../components/crud/CrudPage';
import CrudTableBtn from '../../../components/crud/CrudTableBtn';
import ConfirmModal from '../../../components/crud/ConfirmModal/ConfirmModal';


const UsersPage = () => {

    const columns = useMemo<Column<User>[]>(
        () => [
            {
                accessorKey: 'email',
                header: 'E-mail',
            },
            {
                accessorKey: 'first_name',
                header: 'Nome',
            },
            {
                accessorKey: 'last_name',
                header: 'Sobrenome',
            },
            {
                accessorKey: 'password',
                header: 'Senha',
                hideColumn: true,
                field: {
                    type: 'password',
                },
            },
            {
                accessorKey: 'password_confirm',
                header: 'Confirmação de Senha',
                hideColumn: true,
                field: {
                    type: 'password',
                },
            },
        ], [],
    );

    /**
     * RESET PASSWORD CONFIRMATION
     */
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [modalUserEntity, setModalUserEntity] = useState<any>(null);

    const openResetPasswordModal = (entity = null) => {
        setModalUserEntity(entity);
        setResetPasswordModalOpen(true);
    };

    const handleResetPasswordCancel = () => {
        setResetPasswordModalOpen(false);
    };

    const handleResetPasswordSubmit = async (row: any, id: number) => {
        api.post(`/api/v0/users/password-reset/`, {
            'email': row.email,
        }).then(() => {
            enqueueSnackbar('E-mail enviado com sucesso!', {variant: 'success'});
            setResetPasswordModalOpen(false);
        }).catch(() => {
            enqueueSnackbar('Erro ao enviar e-mail!', {variant: 'error'});
        });
    };


    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{pt: 4}}>
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Acessos', 'Usuários']}/>
                </Box>
            </Stack>

            <CrudPage
                name={{
                    singular: 'Usuário',
                    plural: 'Usuários',
                }}
                route={appRoutes.Users}
                endpoint={'/api/v0/users/'}
                columns={columns}
                crudEnableDelete={false}
                crudEnableUpdate={false}
                rowActions={(entity: User) => {
                    if (!hasPermission('users.send_password_reset')) {
                        return null;
                    }

                    return (<Stack
                        direction={'row'}
                    >
                        <CrudTableBtn
                            entity={entity}
                            onClick={openResetPasswordModal}
                            tooltip={'Redefinir Senha'}
                            icon={PasswordIcon}
                            color={'warning'}
                        />
                    </Stack>);
                }}
            />

            {/** RESET PASSWORD MODAL */}
            <ConfirmModal
                // MODAL CONTROL
                open={resetPasswordModalOpen}
                entity={modalUserEntity}

                // CONTENT
                title={'Redefinir Senha'}
                message={'Deseja enviar um e-mail de redefinição de senha?'}
                btnText={'Enviar'}

                // EVENTS
                onSubmit={handleResetPasswordSubmit}
                onCancel={handleResetPasswordCancel}
            />

        </>
    );

};

export default UsersPage;