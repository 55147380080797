import React, {Dispatch, SetStateAction} from 'react';

import CrudText from '../../../../components/crud/fields/CrudText';

import {ZapiInstance} from '../Instance.d';


const InstanceIdField = (
    values: ZapiInstance,
    setValues: Dispatch<SetStateAction<ZapiInstance>>,
    errors: Record<string, string>,
    readOnly: (e: ZapiInstance) => boolean,
) => {

    if (values?.type !== 'instancezapi') {
        return null;
    }

    return (
        <CrudText
            key={'instance_id'}
            column={{
                accessorKey: 'instance_id',
                header: 'ID da Instância',
            }}
            values={values}
            setValues={setValues}
            errors={errors}
        />
    );
};

export default InstanceIdField;
