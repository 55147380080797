import React, {useState} from 'react';

import {useSnackbar} from 'notistack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import SendIcon from '@mui/icons-material/Send';
import QueryIcon from '@mui/icons-material/QueryStats';

import api from '../../../services/api';
import AppRoutes from '../../../routes/appRoutes';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ConfirmModal from '../../../components/crud/ConfirmModal';
import CrudAPI, {Column} from '../../../components/crud/CrudPage';
import QueryModal from '../../../components/crud/modals/QueryModal';

import InstanceField from './components/InstanceField';
import DatabaseField from './components/DatabaseField';
import MessageField from './components/MessageField';
import ParametersField from './components/ParametersField';
import TemplateIdField from './components/TemplateIdField';

import {Template} from './Template.d';


const name = {
    singular: 'Template',
    plural: 'Templates',
};

const formatPayload = (formData: FormData, entity_id: number, values: Template) => {
    const payload: Record<string, any> = {
        name: values.name,
        cron: values.cron,
        type: values.type,
    }

    payload['queryset'] = {
        query: values.queryset.query,
    }

    if (values.is_external_database) {
        payload['queryset']['database'] = values.queryset.database.id;
    }

    if (!entity_id) {
        payload['instance'] = values.instance.id;
    }

    if (values.type === 'templatefromparameters') {
        payload['template_id'] = values.template_id;
        payload['parameters'] = values.parameters.map((param) => param.id === 0 ? {name: param.name} : param);
    }

    if (values.type === 'templatefrommessage') {
        payload['message'] = values.message;
    }

    return payload;
};


const TemplatesPage = () => {

    const {enqueueSnackbar} = useSnackbar();

    const columns: Column<Template>[] = [
        {
            accessorKey: 'name',
            header: 'Nome',
        },
        {
            accessorKey: 'header_1',
            header: 'Configurações de Envio',
            hideColumn: true,
            field: {
                type: 'header',
                separator: true,
            },
        },
        {
            accessorKey: 'instance.name',
            header: 'Instância',
            hideColumn: true,
            field: {
                type: 'custom',
                component: InstanceField,
            },
        },
        {
            accessorKey: 'is_external_database',
            header: 'Consultar base de dados externa',
            hideColumn: true,
            field: {
                type: 'check',
            },
        },
        {
            accessorKey: 'queryset.database.description',
            header: 'Base de Dados',
            hideColumn: true,
            field: {
                type: 'custom',
                component: DatabaseField,
            },
        },
        {
            accessorKey: 'header_2',
            header: 'Dados da Mensagem',
            hideColumn: true,
            field: {
                type: 'header',
                separator: true,
            },
        },
        {
            accessorKey: 'message',
            header: 'Mensagem',
            hideColumn: true,
            field: {
                type: 'custom',
                component: MessageField,
            },
        },
        {
            accessorKey: 'template_id',
            header: 'Id do Template',
            hideColumn: true,
            field: {
                type: 'custom',
                component: TemplateIdField,
            },
        },
        {
            accessorKey: 'parameters',
            header: 'Parâmetros',
            hideColumn: true,
            field: {
                type: 'custom',
                component: ParametersField,
                default: [],
            }
        },
        {
            accessorKey: 'queryset.query',
            header: 'Query',
            hideColumn: true,
            field: {
                multiline: true,
            },
        },
        {
            accessorKey: 'cron',
            header: 'Periodicidade',
            field: {
                type: 'cron',
            },
        },
        {
            accessorKey: 'status',
            header: 'Status',
            enableEditing: false,
            Cell: ({row}) => {
                return (
                    <Switch
                        checked={toggledEntity?.id === row.original.id || row.original.is_active}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleRowToggle(row.original, e.target.checked);
                        }}
                    />
                );
            },
        },
    ];

    /**
     * REFRESH CONTROL
     */
    const [refresh, setRefresh] = useState(false);

    /**
     * TOGGLE STATUS MODAL
     */
    const [toggledOpen, setToggledOpen] = useState(false);
    const [toggledEntity, setToggledEntity] = useState<Template | null>(null);


    const handleRowToggle = (entity: Template, checked: boolean) => {
        if (!checked) {
            setTemplateStatus(entity, false);
            return;
        }

        setToggledEntity(entity);
        setToggledOpen(true);
    };

    const setTemplateStatus = (entity: Template, is_active: boolean, setLoading?: any) => {
        if (setLoading) {
            setLoading(true);
        }

        api.patch(
            `/api/v0/messaging/templates/${entity.id}/status/`,
            {
                'is_active': is_active,
            },
        ).then(() => {
            enqueueSnackbar('Status alterado com sucesso!', {variant: 'success'});
            setSendConfirmOpen(false);
        }).catch((error) => {
            enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao alterar status!', {variant: 'error'});
        }).finally(() => {
            if (setLoading) {
                setLoading(false);
            }
            setRefresh(v => !v);
            setToggledOpen(false);
            setToggledEntity(null);
        });
    };

    const handleToggledSubmit = (entity: Template, setLoading: any) => {
        setTemplateStatus(entity, true, setLoading);
    };

    const handleToggledCancel = (entity: Template) => {
        setToggledEntity(null);
        setToggledOpen(false);
    };

    /**
     * SEND CONFIRM MODAL
     */
    const [sendConfirmOpen, setSendConfirmOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Template | null>(null);

    const handleSendTemplate = (entity: Template, setLoading: any) => {
        setLoading(true);
        api.post(`/api/v0/messaging/templates/${entity.id}/send/`).then(() => {
            enqueueSnackbar('Template enviado com sucesso!', {variant: 'success'});
            setSendConfirmOpen(false);
        }).catch((error) => {
            enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao enviar template!', {variant: 'error'});
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleCancelSend = () => {
        setSendConfirmOpen(false);
    };


    /**
     * GET QUERYSET RESULT
     */
    const [queryResultOpen, setQueryResultOpen] = useState(false);

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{pt: 4}}
            >
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Mensageria', name.singular]}/>
                </Box>
            </Stack>

            <CrudAPI
                // STRUCTURE
                name={name}
                route={AppRoutes.Databases}
                endpoint={'/api/v0/messaging/templates/'}
                columns={columns}

                // TABLE CONTROL
                setControlledRefresh={setRefresh}
                controlledRefresh={refresh}

                // MODAL VISUAL
                modalWidth={'md'}

                // ACTIONS
                crudEnableDelete={false}
                formatPayload={formatPayload}
                extraRowActions={[
                    {
                        icon: SendIcon,
                        tooltip: 'Enviar',
                        color: 'info',
                        onClick: (entity: Template) => {
                            setModalEntity(entity);
                            setSendConfirmOpen(true);
                        },
                    },
                    {
                        icon: QueryIcon,
                        tooltip: 'Visualizar Consulta',
                        color: 'info',
                        onClick: (entity: Template) => {
                            setModalEntity(entity);
                            setQueryResultOpen(true);
                        },
                    },
                ]}
            />

            {/** TEMPLATE SEND CONFIRM */}
            <ConfirmModal
                // Control
                open={sendConfirmOpen}
                entity={modalEntity}

                // Structure
                title={'Enviar Template'}
                message={'Deseja enviar o template?'}
                btnText={'Enviar'}
                warningMessage={'Esta ação não pode ser desfeita!'}

                // Events
                onSubmit={handleSendTemplate}
                onCancel={handleCancelSend}
            />


            {/** TEMPLATE STATUS CONFIRM */}
            <ConfirmModal
                // Control
                open={toggledOpen}
                entity={toggledEntity}

                // Structure
                title={'Ativar Template'}
                message={'Deseja ativar este template?'}
                btnText={'Salvar'}
                warningMessage={'Ao ativar este template, ele entrará na fila de envio automatico!'}

                // Events
                onSubmit={handleToggledSubmit}
                onCancel={handleToggledCancel}
            />

            {/** QUERYSET RESULT MODAL */}
            <QueryModal
                // Control
                open={queryResultOpen}
                setOpen={setQueryResultOpen}
                entity={modalEntity}

                // Structure
                endpoint={`/api/v0/messaging/templates/${modalEntity?.id}/query/results/`}
                title={`Resultados da Consulta do Template: ${modalEntity?.name}`}
            />
        </>
    );
};

export default TemplatesPage;