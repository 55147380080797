import AppRoutes from './appRoutes';

import DatabasesPage from '../pages/messaging/Databases';
import InstancesPage from '../pages/integration/Instances';
import MessagesPage from '../pages/messaging/Messages';
import SourcesPage from '../pages/datasets/Sources';
import TablesPage from '../pages/datasets/Tables';
import TemplatesPage from '../pages/messaging/Templates';
import UsersPage from '../pages/access/Users/Users';


const PrivatePages = [
    // ACCESS
    {
        route: AppRoutes.Users,
        component: UsersPage,
    },
    // TEMPLATES
    {
        route: AppRoutes.Databases,
        component: DatabasesPage,
    },
    {
        route: AppRoutes.Templates,
        component: TemplatesPage,
    },
    // MESSAGING
    {
        route: AppRoutes.Messages,
        component: MessagesPage,
    },
    // INSTANCES
    {
        route: AppRoutes.Instances,
        component: InstancesPage,
    },
    // DATASETS
    {
        route: AppRoutes.Sources,
        component: SourcesPage,
    },
    {
        route: AppRoutes.Tables,
        component: TablesPage,
    },
];


export default PrivatePages;