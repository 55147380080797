import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppRoutes from '../../../routes/appRoutes';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CrudAPI, {Column} from '../../../components/crud/CrudPage';
import {Source} from './index';


export const SOURCE_TYPES = {
    sourcem8: 'Api M8',
}

const columns: Column<Source>[] = [
    {
        accessorKey: 'name',
        header: 'Nome',
    },
    {
        accessorKey: 'type',
        header: 'Tipo',
        field: {
            type: 'select',
            default: 'm8',
            options: SOURCE_TYPES,
        },
        Cell: ({row}) => SOURCE_TYPES[row.original.type]
    },
    {
        accessorKey: 'domain',
        header: 'Domínio',
        hideColumn: true,
    },
    {
        accessorKey: 'username',
        header: 'Usuário',
        hideColumn: true,
    },
    {
        accessorKey: 'password',
        header: 'Senha',
        hideColumn: true,
    },
] as Column<Source>[];


const name = {
    singular: 'Fonte de Dados',
    plural: 'Fontes de Dados',
};


const SourcesPage = () => {


    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{pt: 4}}
            >
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Datasets', name.singular]}/>
                </Box>
            </Stack>

            <CrudAPI
                name={name}
                route={AppRoutes.Sources}
                endpoint={'/api/v0/datasets/sources/'}
                columns={columns}
            />

        </>
    );
};

export default SourcesPage;