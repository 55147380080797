import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import {CrudSelectProps} from '../CrudSelect';
import {TData} from '../../CrudPage';

const CrudSelect = <T extends TData>(props: CrudSelectProps<T>) => {

    const {
        column,
        values,
        setValues,
        errors,
        readOnly = false,
        required = false,
    } = props;

    const options = column.field.options;

    return (<FormControl
        fullWidth
        key={column.accessorKey}
        error={errors?.hasOwnProperty(column.accessorKey) ?? false}
    >
        <InputLabel id={column.accessorKey}>{column.header}</InputLabel>
        <Select
            id={column.accessorKey}
            labelId={column.accessorKey}
            value={values[column.accessorKey]}
            label={column.header}
            disabled={readOnly}
            required={required}
            onChange={(event: SelectChangeEvent, val: any) => {
                setValues({
                    ...values,
                    [column.accessorKey]: val.props.value,
                });
            }}
        >
            {Object.keys(options).map(option => {
                return (
                    <MenuItem
                        key={option}
                        value={option}
                        children={options[option]}
                    />
                );
            })}
        </Select>
        {errors?.hasOwnProperty(column.accessorKey) ?
            <FormHelperText>{errors[column.accessorKey]}</FormHelperText> : <></>}
    </FormControl>);
};

export default CrudSelect;