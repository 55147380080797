import React, {Dispatch, SetStateAction} from 'react';

import {ZapiInstance} from '../Instance.d';

import CrudSelect from '../../../../components/crud/fields/CrudSelect';


export const INSTANCE_TYPES = {
    instancezapi: 'Z-API',
    instancenexchat: 'NexChat',
};

const TypeField = (
    values: ZapiInstance,
    setValues: Dispatch<SetStateAction<ZapiInstance>>,
    errors: Record<string, string>,
    readOnly: (e: ZapiInstance) => boolean,
) => {
    return (
        <CrudSelect
            key={'type_id'}
            column={{
                accessorKey: 'type',
                header: 'Tipo',
                field: {
                    type: 'select',
                    options: INSTANCE_TYPES,
                    default: 'instancezapi',
                }
            }}
            values={values}
            setValues={setValues}
            errors={errors}
            readOnly={!!values.id}
        />
    );
};

export default TypeField;
