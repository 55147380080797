import React, {Dispatch, SetStateAction} from 'react';

import CrudText from '../../../../components/crud/fields/CrudText';

import {TemplateFromMessage} from '../Template.d';


const MessageField = (
    values: TemplateFromMessage,
    setValues: Dispatch<SetStateAction<TemplateFromMessage>>,
    errors: Record<string, string>,
    readOnly: (e: TemplateFromMessage) => boolean,
) => {
    if (values.type !== 'templatefrommessage') {
        return null;
    }

    return (
        <CrudText
            key={'message_id'}
            column={{
                accessorKey: 'message',
                header: 'Mensagem',
                hideColumn: true,
                field: {
                    type: 'text',
                    multiline: true,
                },
            }}
            values={values}
            setValues={setValues}
            errors={errors}
        />
    );
};

export default MessageField;
