import React, {Component} from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/PersonRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import api from '../../../services/api';
import {fetchUserData} from '../../../services/storage/user';
import {setToken} from '../../../services/auth';

import AppLogo from '../../../assets/img/brand/zapzoom/zapzoom-logo.png';
import {fetchCompanyVisualData} from '../../../services/storage/company';

class Login extends Component {
    state = {
        email: '',
        password: '',
        error: '',
        showPassword: false,
    };

    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.setState({showPassword: true});
    };

    handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.setState({showPassword: false});
    };

    handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {

        // Preventing to submit form
        e.preventDefault();

        // Getting credentials from the state
        const {
            email,
            password,
        } = this.state;

        // If it does not have one of the credentials (despite the required prop)
        if (!email || !password) {
            this.setState({error: 'Preencha e-mail e senha para continuar!'});
        } else {
            // Try the login on the API
            try {
                // Call the login route
                const response = await api.post('/api/v0/users/login/', {
                    email,
                    password,
                });

                // Store the access token on the local storage
                setToken(response.data['access_token']);

                // Get user data and save on localstorage
                fetchUserData();
                fetchCompanyVisualData();

                // Redirect to the dashboard
                window.location.replace('/');
            } catch (err) {
                // Show the error on top of the login form
                this.setState({error: 'Usuário ou senha inválidos.'});
            }
        }
    };

    render() {
        return (<main>
            <Stack alignItems={'center'} justifyContent={'center'} sx={{
                height: '100vh',
                px: 3,
                py: 0,
                my: 0,
            }}>
                <Stack sx={{
                    width: '100%',
                    maxWidth: '350px',
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        mb: 3,
                    }}>
                        <img src={AppLogo} alt={'Logomarca'} width={'100%'}/>
                    </Box>
                    {this.state.error && (<Alert severity={'error'} sx={{mb: 2}}>{this.state.error}</Alert>)}
                    <form onSubmit={this.handleLogin}>
                        <Stack sx={{
                            width: '100%',
                            gap: '1.5rem',
                        }}>
                            <TextField
                                label={'E-mail'}
                                name={'email'}
                                onChange={e => this.setState({email: e.target.value})}
                                InputProps={{
                                    startAdornment: (<InputAdornment position={'start'}>
                                        <PersonIcon/>
                                    </InputAdornment>),
                                }}
                            />
                            <TextField
                                type={this.state.showPassword ? 'text' : 'password'}
                                label={'Senha'}
                                name={'password'}
                                onChange={e => this.setState({password: e.target.value})}
                                InputProps={{
                                    startAdornment: (<InputAdornment position={'start'}>
                                        <KeyIcon/>
                                    </InputAdornment>),
                                    endAdornment: (<InputAdornment position={'end'}>
                                        <IconButton
                                            onMouseUp={this.handleMouseUpPassword}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>),
                                }}
                            />
                            <Button variant={'contained'} type={'submit'} size={'large'} sx={{p: 1.5}}>
                                Entrar
                            </Button>
                        </Stack>
                    </form>
                </Stack>
            </Stack>
        </main>);
    }
}


export default (Login);