import React, {useEffect, useState} from 'react';

import 'dayjs/locale/pt-br';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import {CrudApiSelectFieldProps} from '../fields/CrudApiSelect';
import CrudForm from '../CrudForm';
import {CrudModalProps} from '../CrudModal';
import {TData} from '../CrudPage';

import setNestedValue from '../../utils/setNestedValue';


const CrudModal = <T extends TData>(props: CrudModalProps<T>) => {

    const {
        open,
        entity,
        columns,
        errors,
        readTitle,
        createTitle,
        updateTitle,
        onCancel,
        onSubmit,
        extraModalActions,
        readOnly = false,

        // Visual
        modalWidth = 'sm',

    } = props;

    const [values, setValues] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    /**
     * LISTEN TO ENTITY CHANGE AND FILL THE FORM FIELDS
     */
    useEffect(() => {

        // Clear all field values
        let newValues = columns.reduce((acc: any, column) => {
            let key = column.accessorKey;

            if ('field' in column) {
                let field = column.field as CrudApiSelectFieldProps;
                if ('name' in field) {
                    key = field.name;
                }
            }

            let type = column.field?.type ?? '';
            if (key !== 'id' && type !== 'header') {
                setNestedValue(acc, key, column.field?.default ?? '');
            }
            return acc;
        }, {});

        if (entity) {
            newValues = {
                ...newValues,
                ...entity,
            };
        }

        setUnsavedChanges(false);
        setValues(newValues);

    }, [entity]);


    /**
     * EVENT HANDLERS
     */
    const handleSubmit = () => {
        let formData = new FormData();

        for (let [key, val] of Object.entries(values)) {
            let value = getFieldValue(val);
            if (value || value === 0 || value === false) {
                if (Array.isArray(value)) {
                    value.forEach(val => formData.append(key, val));
                } else {
                    formData.append(key, value);
                }
            }
        }

        onSubmit(formData, entity?.id ?? null, values);
    };

    const handleCancel = () => onCancel();

    const getFieldValue = (field: any) => {
        if (field === null) {
            return;
        }

        // Object, but not array or file
        if ((typeof (field) === 'object') && !(Array.isArray(field) || (field instanceof File))) {
            return field.id;
        }

        if (Array.isArray(field)) {
            field = field.map(f => getFieldValue(f));
        }

        return field;
    };


    /**
     * COMPONENT RENDER
     */
    return (
        <Dialog
            open={open}
            maxWidth={modalWidth}
            fullWidth
        >
            <DialogTitle textAlign={'center'}>
                {readOnly ? readTitle : (entity ? updateTitle : createTitle)}
                {unsavedChanges && ' *'}
            </DialogTitle>
            <DialogContent sx={{pt: 1}}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            py: 1,
                            width: '100%',
                            minWidth: {
                                xs: '300px',
                                sm: '360px',
                                md: '400px',
                            },
                            gap: '1.5rem',
                        }}
                    >
                        <CrudForm
                            columns={columns}
                            values={values}
                            setValues={(vals: any) => {
                                setUnsavedChanges(true);
                                setValues(vals);
                            }}
                            errors={errors}
                            readOnly={readOnly}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions
                sx={{
                    p: '1.25rem',
                    justifyContent: 'space-between',
                    gap: 1,
                }}
            >
                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    {extraModalActions && extraModalActions(values, unsavedChanges)}
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    <Button onClick={handleCancel}>Cancelar</Button>
                    {!readOnly && (
                        <Button
                            onClick={handleSubmit}
                            variant={'contained'}
                            color={'primary'}
                            children={'Salvar'}
                        />
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default CrudModal;