import React from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {NavItemProps} from '../NavItem';


const NavItem = (props: NavItemProps) => {

    const {
        title,
        icon: Icon,
        route,
        image,
        onClick,
        setShow,
    } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        setShow(false);

        if (route.path) {
            navigate(route.path);
        }
    };

    return (<Button
        fullWidth
        disableElevation
        color={'secondary'}
        onClick={handleClick}
        sx={{
            minHeight: 40,
            maxHeight: 40,
            display: 'flex',
            justifyContent: 'start',
            gap: 1,
            '&:hover': {
                backgroundColor: '#FFFFFF66',
            },
        }}
    >
        {Icon && (
            <Icon
                sx={{
                    fontSize: '14px',
                    color: 'white',
                }}
            />
        )}
        {image && <img width={20} height={20} src={image} alt={'sidebar'}/>}
        <Typography
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            fontSize={'0.9rem'}
            sx={{
                textTransform: 'none',
                whiteSpace: 'nowrap',
            }}
        >
            {title}
        </Typography>
    </Button>);
};

export default NavItem;