import React, {ChangeEvent} from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import getNestedValue from '../../../utils/getNestedValue';
import hasNestedValue from '../../../utils/hasNestedValue';
import setNestedValue from '../../../utils/setNestedValue';

import {CrudCheckBoxProps} from '../CrudCheckBox';
import {TData} from '../../CrudPage';

const CrudCheckBox = <T extends TData>(props: CrudCheckBoxProps<T>) => {

    const {
        column,
        values,
        setValues,
        errors,
        readOnly = false,
    } = props;

    const {
        accessorKey,
        header,
    } = column;

    return (
        <FormControl
            error={hasNestedValue(errors, accessorKey)}
            sx={{justifyContent: 'center'}}
        >
            <FormControlLabel
                label={header}
                control={<Checkbox
                    name={accessorKey}
                    disabled={readOnly}
                    checked={getNestedValue(values, accessorKey, false)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setValues(
                            setNestedValue(values, accessorKey, e.target.checked),
                        );
                    }}
                    sx={{
                        pb: 0,
                        pt: 0,
                        mb: 0,
                        mt: 0,
                    }}
                />}
                sx={{
                    pb: 0,
                    pt: 0,
                    mb: 0,
                    mt: 0,
                }}
            />
            <FormHelperText children={errors && getNestedValue(errors, accessorKey, '')}/>
        </FormControl>
    );
};

export default CrudCheckBox;