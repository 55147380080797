import React from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';

import CrudHeader from '../CrudHeader';
import {Column, TData} from './index';
import CrudTable from '../CrudTable';
import {ReportPageProps} from './ReportPage.d';


const ReportPage = <T extends TData>(props: ReportPageProps<T>) => {

    const {
        // STRUCTURE
        name,
        endpoint,
        columns,
    } = props;


    /**
     * COLUMN DEFINITION
     */
    const crudColumns = columns.map((col: Column<T>): Column<T> => {
        if (col.accessorKey === 'id') {
            return {
                ...col,
                enableSorting: false,
                enableColumnActions: false,
                size: 10,
                Cell: ({row}) => row.original[col.accessorKey].padStart(5, '0'),
            };
        }
        if (col.type === 'check') {
            return {
                Cell: ({row}) => row.original[col.accessorKey] ? <CheckIcon/> : <CircleIcon/>,
                ...col,
            };
        }
        if (col.type === 'date') {
            return {
                size: 125,
                Cell: ({row}) => row.original[col.accessorKey].split('-').reverse().join('/'),
                ...col,
            };
        }
        if (col.type === 'datetime') {
            return {
                Cell: ({row}) => {
                    let [date, hour] = row.original[col.accessorKey].split('T');
                    date = date.split('-').reverse().join('/');
                    hour = hour.split('.')[0].split('Z')[0];
                    return `${date} ${hour}`;
                }, ...col,
            };
        }
        return col;
    });


    /**
     * COMPONENT RENDER
     */
    return (
        <>

            <CrudHeader
                // STRUCTURE
                name={name}

                // PERMISSIONS
                enableCreate={false}
            />

            <CrudTable
                // STRUCTURE
                columns={crudColumns}
                endpoint={endpoint}

                // PERMISSIONS
                enableUpdate={false}
            />

        </>
    );
};


export default ReportPage;