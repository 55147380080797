import React, {useState} from 'react';

import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';

import ClearIcon from '@mui/icons-material/Clear';
import DatasetsIcon from '@mui/icons-material/Storage';
import MenuIcon from '@mui/icons-material/Menu';
import MessagingIcon from '@mui/icons-material/Message';
import SendedMessage from '@mui/icons-material/Message';
import TableIcon from '@mui/icons-material/TableView';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import AppRoutes from '../../routes/appRoutes';

import CollapsableNavItem from './CollapsableNavItem';
import Item, {NavItemProps} from './NavItem';

import AppIcon from '../../assets/img/brand/zapzoom/zapzoom-icon.png';
import AppLogo from '../../assets/img/brand/zapzoom/zapzoom-logo-white.png';

import {getCachedPermissions, getCachedSuperUser, removePermissionsData} from '../../services/storage/permissions';
import {removeCompaniesVisualData} from '../../services/storage/company';
import {removeUserData} from '../../services/storage/user';


const SideNavWrapper = styled(Box)<{ show: number }>(({
                                                          theme,
                                                          show,
                                                      }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '100%',
        display: show ? 'block' : 'none',
    },
    [theme.breakpoints.up('md')]: {
        width: '260px',
        display: 'block',
    },
    background: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    maxHeight: '100%',
}));

const SidebarDivider = styled(Divider)(({theme}) => ({
    marginTop: '10px',
    marginBottom: '10px',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1.2,
    opacity: 0.5,
}));


const Sidebar = () => {

    const [show, setShow] = useState(false);

    const onCollapse = () => setShow(!show);

    /**
     * PERMISSIONS
     */
    const permissions = getCachedPermissions();
    const superuser = getCachedSuperUser();

    const NavItem = (props: Omit<NavItemProps, 'setShow' | 'permissions' | 'superuser'>) => {
        return (
            <Item
                {...props}
                setShow={setShow}
                permissions={permissions}
                superuser={superuser}
            />
        );
    };

    const reloadLocalStorage = () => {
        removeCompaniesVisualData();
        removePermissionsData();
        removeUserData();
        window.location.reload();
    };

    return (<>
            <AppBar
                position={'static'}
                sx={{
                    px: 4,
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <img alt={'Icone'} src={AppIcon} width={25}/>
                    <IconButton size={'large'} edge={'start'} onClick={onCollapse} sx={{color: 'white'}}>
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <SideNavWrapper show={show ? 1 : 0}>

                <SimpleBarReact
                    style={{maxHeight: '100%'}}
                >
                    <Box
                        sx={{
                            px: 2,
                            py: 3,
                        }}
                    >
                        <Stack
                            direction={'row-reverse'}
                            sx={{
                                display: {md: 'none'},
                            }}
                        >
                            <IconButton
                                size={'large'}
                                edge={'start'}
                                onClick={onCollapse}
                            >
                                <ClearIcon/>
                            </IconButton>
                        </Stack>

                        <Stack>
                            <Box
                                sx={{
                                    py: 1.8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 1.2,
                                }}
                            >
                                <img
                                    alt={'Logomarca'}
                                    src={AppLogo}
                                    onClick={reloadLocalStorage}
                                    style={{
                                        maxWidth: '80%',
                                    }}
                                />
                            </Box>

                            <SidebarDivider/>

                            <NavItem title={'Mensagens Enviadas'} route={AppRoutes.Messages} icon={SendedMessage}/>

                            <SidebarDivider/>

                            <CollapsableNavItem title={'Mensageria'} icon={MessagingIcon} permissions={permissions}>
                                <NavItem title={'Bancos de Dados'} route={AppRoutes.Databases}/>
                                <NavItem title={'Templates'} route={AppRoutes.Templates}/>
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Integração'} icon={WhatsAppIcon} permissions={permissions}>
                                <NavItem title={'Instâncias'} route={AppRoutes.Instances}/>
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Datasets'} icon={DatasetsIcon} permissions={permissions}>
                                <NavItem title={'Fontes de Dados'} route={AppRoutes.Sources}/>
                                <NavItem title={'Tabelas'} route={AppRoutes.Tables}/>
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Acessos'} icon={TableIcon} permissions={permissions}>
                                <NavItem title={'Usuários'} route={AppRoutes.Users}/>
                            </CollapsableNavItem>

                        </Stack>
                    </Box>
                </SimpleBarReact>
            </SideNavWrapper>
        </>
    );
};

export default Sidebar;
