import React, {ReactNode, useEffect, useState} from 'react';

import {MaterialReactTable, MRT_Row} from 'material-react-table';
import {MRT_Localization_PT_BR} from 'material-react-table/locales/pt-BR';

import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';

import api from '../../../services/api';

import {TData} from '../CrudPage';
import CrudTableBtn from '../CrudTableBtn';
import {CrudTableProps} from '../CrudTable';

const CrudTable = <T extends TData>(props: CrudTableProps<T>) => {

    const {
        // STRUCTURE
        columns,
        endpoint,

        // ACTIONS
        refresh,
        rowActions,
        extraRowActions,
        handleCreateUpdate,
        rowDoubleClick,
        getIsReadOnly,

        // TABLE CONTROL
        defaultSorting = [{id: '', desc: false}],

        // PERMISSIONS
        enableUpdate = true,
    } = props;

    /**
     * TABLE CONTROL
     */
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState<Array<{ id: string, value: string }>>([]);
    const [sorting, setSorting] = useState(defaultSorting);
    const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 10});


    // API QUERY
    const {
        data,
        isError,
        isFetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['table-data', globalFilter, pagination.pageIndex, pagination.pageSize, sorting],
        queryFn: async () => (await api.get(endpoint, {
            params: {
                ...columnFilters.reduce((query: Record<string, string>, filter) => {
                    let queryFilter = filter.value;

                    // If the filter on the displayed columns
                    if (columns.map(c => c.accessorKey).includes(filter.id)) {

                        // Format the field based on filter variant
                        const filter_variant = columns.filter(c => c.accessorKey === filter.id)[0].filterVariant;
                        switch (filter_variant) {
                            case 'date':
                                queryFilter = filter.value.split('/').reverse().join('-');
                                break;
                        }

                    }

                    query[filter.id.replaceAll('.', '__')] = queryFilter;
                    return query;
                }, {}),
                search: globalFilter ?? '',
                offset: `${pagination.pageIndex * pagination.pageSize}`,
                limit: `${pagination.pageSize}`,
                ordering: sorting.map(col => (col.desc ? '-' : '') + col.id?.replaceAll('.', '__') ?? '').join(','),
            },
        })).data,
    });

    useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        setPagination(e => ({
            ...e,
            pageIndex: 0,
        }));

        refetch().then(() => {
        });
    }, [columnFilters, refetch]);

    // Refetch from outside
    useEffect(() => {
        refetch().then(() => {
        });
    }, [refresh, refetch]);

    /**
     * ROW ACTIONS
     */
    const renderRowActions = (row: MRT_Row<T>): ReactNode => {
        const entity = row.original;

        if (rowActions) {
            return rowActions(entity);
        }

        const readOnly = getIsReadOnly ? getIsReadOnly(entity) : false;

        return (<Box
            sx={{
                display: 'flex',
                justifyContent: 'end',
            }}
        >

            {enableUpdate && <CrudTableBtn
                entity={entity}
                onClick={handleCreateUpdate}
                tooltip={(readOnly || !enableUpdate) ? 'Visualizar' : 'Editar'}
                icon={(readOnly || !enableUpdate) ? VisibilityIcon : EditIcon}
            />}

            {extraRowActions ? extraRowActions.map((action, i) => {
                if (action.buildLink) {
                    action.link = action.buildLink(entity);
                }
                if (!action.getShow || action.getShow(entity)) {
                    return (
                        <CrudTableBtn
                            entity={entity}
                            {...action}
                            key={`action-${i}`}
                        />
                    );
                }
            }) : null}

        </Box>);
    };


    /**
     * COMPONENT RENDER
     */
    return (<>

        {/** TABLE */}
        <MaterialReactTable
            // Data
            columns={columns.filter(col => !col?.hideColumn)}
            data={data?.results ?? []}
            rowCount={data?.count ?? 0}

            // Config
            localization={{
                ...MRT_Localization_PT_BR, ...{actions: ''},
            }}
            autoResetPageIndex={false}
            enableDensityToggle={false}
            enableColumnResizing={true}
            enableHiding={false}
            enableEditing
            defaultColumn={{
                enableResizing: true,
                grow: true,
            }}

            // Row actions
            positionActionsColumn={'last'}
            renderRowActions={({row}) => renderRowActions(row)}
            muiTableBodyRowProps={({row}) => ({
                onDoubleClick: () => {
                    if (rowDoubleClick) {
                        return rowDoubleClick(row.original);
                    }

                    if (enableUpdate) {
                        handleCreateUpdate(row.original);
                    }
                },
                sx: {cursor: 'pointer'},
            })}

            // Toolbar actions
            renderTopToolbarCustomActions={() => (<>
                <Tooltip arrow title={'Refresh Data'}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
            </>)}

            // Filters
            manualFiltering={true}
            onColumnFiltersChange={filters => setColumnFilters(filters as Array<{ id: string, value: string }>)}
            onGlobalFilterChange={setGlobalFilter}

            // Pagination
            manualPagination={true}
            onPaginationChange={setPagination}

            // Sorting
            manualSorting={true}
            onSortingChange={setSorting}

            // Alerts
            muiToolbarAlertBannerProps={isError ? {
                color: 'error',
                children: 'Erro ao carregar dados.',
            } : undefined}

            state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isFetching,
                sorting,
            }}
        />

    </>);
};


const CrudPageWithQuery = <T extends TData>(props: CrudTableProps<T>) => (
    <QueryClientProvider client={new QueryClient()}>
        <CrudTable {...props}/>
    </QueryClientProvider>
);

export default CrudPageWithQuery;