import React, {useEffect, useState} from 'react';

import {getRoutePermissions} from '../../../services/storage/permissions';

import Crud from '../Crud';
import CrudHeader from '../CrudHeader';
import {CrudPageProps, TData} from '../CrudPage';


const CrudPage = <T extends TData>(props: CrudPageProps<T>) => {

    const {
        // STRUCTURE
        name,
        route,
        columns,
        endpoint,

        // ACTIONS
        rowActions,
        rowDoubleClick,
        extraRowActions,
        extraCrudActions,
        extraModalActions,
        getIsReadOnly,
        handleCreateUpdate,

        // API CONTROL
        formatPayload,

        // TABLE CONTROL
        controlledRefresh,
        setControlledRefresh,

        // MODAL CONTROL
        controlledModalEntity,
        setControlledModalEntity,
        controlledCreateUpdateOpen,
        setControlledCreateUpdateOpen,

        // MODAL VISUAL
        modalWidth,

        // CRUD PERMISSIONS
        crudEnableCreate = true,
        crudEnableUpdate = true,
        crudEnableDelete = true,
    } = props;


    /**
     * PERMISSIONS
     */
    const [enableCreate, setEnableCreate] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [enableDelete, setEnableDelete] = useState(false);

    useEffect(() => {
        const {
            canCreate,
            canUpdate,
            canDelete,
        } = getRoutePermissions(route);
        setEnableCreate(canCreate && crudEnableCreate);
        setEnableUpdate(canUpdate && crudEnableUpdate);
        setEnableDelete(canDelete && crudEnableDelete);
    }, [crudEnableCreate, crudEnableUpdate, crudEnableDelete]);

    /**
     * GLOBAL MODAL ENTITY
     */
    const [uncontrolledModalEntity, setUncontrolledModalEntity] = useState(null);
    const modalEntity = controlledModalEntity ? controlledModalEntity : uncontrolledModalEntity;
    const setModalEntity = controlledModalEntity ? setControlledModalEntity : setUncontrolledModalEntity;

    /**
     * CREATE/UPDATE MODAL
     */
    const [uncontrolledCreateUpdateOpen, setUncontrolledCreateUpdateOpen] = useState(false);
    const createUpdateOpen = controlledCreateUpdateOpen ? controlledCreateUpdateOpen : uncontrolledCreateUpdateOpen;
    const setCreateUpdateOpen = controlledCreateUpdateOpen ? setControlledCreateUpdateOpen : setUncontrolledCreateUpdateOpen;

    const openCreateUpdateModal = (entity: T | null) => {
        if (handleCreateUpdate) {
            handleCreateUpdate(entity);
            return;
        }
        setModalEntity(entity);
        setCreateUpdateOpen(true);
    };


    /**
     * COMPONENT RENDER
     */
    return (
        <>

            <CrudHeader
                // STRUCTURE
                name={name}

                // ACTIONS
                extraCrudActions={extraCrudActions}
                handleCreateUpdate={openCreateUpdateModal}

                // PERMISSIONS
                enableCreate={enableCreate}
            />

            <Crud
                // STRUCTURE
                name={name}
                columns={columns}
                endpoint={endpoint}

                // ACTIONS
                rowActions={rowActions}
                extraRowActions={extraRowActions}
                extraModalActions={extraModalActions}
                handleCreateUpdate={openCreateUpdateModal}
                rowDoubleClick={rowDoubleClick}
                getIsReadOnly={getIsReadOnly}

                // MODULES
                enableCreate={enableCreate}
                enableUpdate={enableUpdate}
                enableDelete={enableDelete}

                // API CONTROL
                formatPayload={formatPayload}

                // MODAL CONTROL
                modalEntity={modalEntity}
                setModalEntity={setModalEntity}
                createUpdateOpen={createUpdateOpen}
                setCreateUpdateOpen={setCreateUpdateOpen}
                controlledRefresh={controlledRefresh}
                setControlledRefresh={setControlledRefresh}

                // MODAL VISUAL
                modalWidth={modalWidth}
            />

        </>
    );
};


export default CrudPage;