import React, {useState} from 'react';

import {useSnackbar} from 'notistack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import SynchronizeIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/WarningRounded';

import api from '../../../services/api';
import AppRoutes from '../../../routes/appRoutes';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ConfirmModal from '../../../components/crud/ConfirmModal';
import CrudAPI, {Column} from '../../../components/crud/CrudPage';

import {Table} from './Tables.d';


const columns: Column<Table>[] = [
    {
        accessorKey: 'name',
        header: 'Nome',
    },
    {
        accessorKey: 'source.name',
        header: 'Fonte de Dados',
        hideColumn: true,
        field: {
            type: 'search',
            labelKey: 'name',
            name: 'source',
            endpoint: '/api/v0/datasets/sources/',
        }
    },
    {
        accessorKey: 'endpoint',
        header: 'Endpoint',
        hideColumn: true,
    },
    {
        accessorKey: 'cron',
        header: 'Periodicidade de Atualização',
        hideColumn: true,
        field: {
            type: 'cron'
        }
    },
    {
        accessorKey: 'last_update_end',
        header: 'Ultima Atualização',
        enableEditing: false,
        type: 'datetime',
        Cell: ({row}) => (
            <Stack
                direction={'row'}
                alignItems={'center'}
            >
                {!row.original.is_up_to_date &&
                    <Tooltip title={'Atualização pendente!'}>
                        <WarningIcon
                            color={'warning'}
                            fontSize={'small'}
                            sx={{mr: 0.5}}
                        />
                    </Tooltip>
                }
                {row.original.last_update_end ?
                    new Date(row.original.last_update_end).toLocaleString().replace(',', '') :
                    '-'
                }
            </Stack>
        )
    },
    {
        accessorKey: 'last_update_time',
        header: 'Tempo',
        enableEditing: false,
        Cell: ({row}) => {
            if (!row.original.last_update_start || !row.original.last_update_end) {
                return '-';
            }

            const start_date = new Date(row.original.last_update_start);
            const end_date = new Date(row.original.last_update_end);

            const time = Math.floor((end_date.getTime() - start_date.getTime()) / 1000);

            if (time < 0) {
                return '-';
            }

            if (time < 60) {
                return `${time} segundos`;
            }

            return `${Math.floor(time / 60)} minutos`;
        },
    },
    {
        accessorKey: 'last_row_count',
        header: 'Linhas',
        enableEditing: false,
        Cell: ({row}) => (
            row.original.last_row_count ? `${row.original.last_row_count} linhas` : '-'
        ),
    },
    {
        // TODO: Transform into toggle field on grid
        accessorKey: 'is_active',
        header: 'Ativo',
        field: {
            type: 'check',
            default: true,
        },
        type: 'check',
    },
] as Column<Table>[];


const name = {
    singular: 'Tabela',
    plural: 'Tabelas',
};


const TablesPage = () => {

    const {enqueueSnackbar} = useSnackbar();

    /**
     * SYNC CONFIRM MODAL
     */
    const [syncConfirmOpen, setSyncConfirmOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Table | null>(null);

    const handleSyncTable = (entity: Table, setLoading: any) => {
        setLoading(true);
        api.post(`/api/v0/datasets/tables/${entity.id}/synchronize/`).then(() => {
            enqueueSnackbar('Sincronização enviada para a fila!', {variant: 'success'});
            setSyncConfirmOpen(false);
        }).catch((error) => {
            enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao sincronizar!', {variant: 'error'});
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleCancelSend = () => {
        setSyncConfirmOpen(false);
    };

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{pt: 4}}
            >
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Datasets', name.singular]}/>
                </Box>
            </Stack>

            <CrudAPI
                name={name}
                route={AppRoutes.Sources}
                endpoint={'/api/v0/datasets/tables/'}
                columns={columns}

                // Actions
                extraRowActions={[
                    {
                        icon: SynchronizeIcon,
                        tooltip: 'Sincronizar Tabela',
                        color: 'info',
                        onClick: (entity: Table) => {
                            setModalEntity(entity);
                            setSyncConfirmOpen(true);
                        },
                    },
                ]}
            />

            {/** SYNC CONFIRM */}
            <ConfirmModal
                // Control
                open={syncConfirmOpen}
                entity={modalEntity}

                // Structure
                title={'Sincronizar Tabela'}
                message={'Deseja sincronizar os dados da tabela?'}
                btnText={'Sincronizar'}
                warningMessage={'Esta ação não pode ser desfeita!'}

                // Events
                onSubmit={handleSyncTable}
                onCancel={handleCancelSend}
            />

        </>
    );
};

export default TablesPage;