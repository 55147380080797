import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {CrudHeaderProps} from '../CrudHeader';
import {TData} from '../../CrudPage';

const CrudHeader = <T extends TData>(props: CrudHeaderProps<T>) => {

    const {
        column,
    } = props;

    const {
        field,
        header,
    } = column;

    return (
        <Box>
            {field?.separator && (
                <Divider
                    sx={{
                        mt: 0,
                        mb: 4,
                        bgcolor: 'secondary.light',
                    }}
                />
            )}
            <Typography
                variant={'subtitle1'}
                sx={{
                    pb: 0,
                    mb: 0,
                }}
                children={header}
            />
        </Box>
    );
};

export default CrudHeader;