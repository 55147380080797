import React from 'react';
import {useSnackbar} from 'notistack';

import api from '../../../../services/api';

import ConfirmModal from '../../ConfirmModal';
import {TData} from '../../CrudPage';
import {DeleteModalProps} from './DeleteModal.d';

const DeleteModal = <T extends TData>(props: DeleteModalProps<T>) => {

    const {
        // STRUCTURE
        name,
        endpoint,

        // MODAL CONTROL
        modalEntity,
        setDeleteOpen,
        deleteOpen,

        // ACTIONS
        refresh,
    } = props;

    const {enqueueSnackbar} = useSnackbar();

    /**
     * MODAL CONTROL
     */
    const handleDeleteCancel = () => {
        setDeleteOpen(false);
    };

    /**
     * ACTIONS
     */
    const handleDeleteSubmit = async (entity: T, setLoading: any) => {
        setLoading(true);

        api.delete(`${endpoint}${entity.id}/`).then(() => {
            setDeleteOpen(false);
            refresh();
        }).catch((error: any) => {
            enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao apagar!', {variant: 'error'});
        }).finally(() => {
            setLoading(false);
        });
    };


    return (
        <ConfirmModal
            // States
            open={deleteOpen}
            entity={modalEntity}

            // Texts
            title={`Apagar ${name.singular}?`}
            message={'Tem certeza que deseja apagar este registro?'}
            warningMessage={'Esta ação não poderá ser revertida!'}
            btnText={'Apagar'}

            // Events
            onSubmit={handleDeleteSubmit}
            onCancel={handleDeleteCancel}
        />
    );
};

export default DeleteModal;