import React, {Dispatch, SetStateAction} from 'react';

import CrudApiSelect from '../../../../components/crud/fields/CrudApiSelect';

import {Template, TemplateFromMessage, TemplateFromParameters} from '../Template.d';


const InstanceField = (
    values: Template,
    setValues: Dispatch<SetStateAction<Template>>,
    errors: Record<string, string>,
    readOnly: (e: Template) => boolean,
) => {
    const getTemplateType = (values: Template) => {
        const instance_type = values?.instance?.type;

        if (instance_type === 'instancenexchat') {
            return 'templatefromparameters';
        }

        return 'templatefrommessage';
    }

    return (
        <CrudApiSelect
            key={'instance_id'}
            column={{
                accessorKey: 'instance.name',
                header: 'Instância',
                field: {
                    type: 'search',
                    labelKey: 'name',
                    name: 'instance',
                    endpoint: '/api/v0/integration/instances/',
                },
            }}
            values={values}
            setValues={(t: Template | SetStateAction<Template>) => {
                if (typeof t === 'function') {
                    throw new Error('Function not expected');
                } else {
                    setValues({
                        ...t,
                        type: getTemplateType(t),
                    } as TemplateFromMessage | TemplateFromParameters);
                }
            }}
            errors={errors}
            readOnly={!!values.id}
        />
    );
};

export default InstanceField;
