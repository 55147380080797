export const PTBR_CRON_LOCALE = {
    everyText: 'a cada',
    emptyMonths: 'todo mês',
    emptyMonthDays: 'todo dia do mês',
    emptyMonthDaysShort: 'dia do mês',
    emptyWeekDays: 'todo dia da semana',
    emptyWeekDaysShort: 'dia da semana',
    emptyHours: 'toda hora',
    emptyMinutes: 'todo minuto',
    emptyMinutesForHourPeriod: 'todo',
    yearOption: 'ano',
    monthOption: 'mês',
    weekOption: 'semana',
    dayOption: 'dia',
    hourOption: 'hora',
    minuteOption: 'minuto',
    rebootOption: 'reiniciar',
    prefixPeriod: 'A cada',
    prefixMonths: 'em',
    prefixMonthDays: 'em',
    prefixWeekDays: 'no',
    prefixWeekDaysForMonthAndYearPeriod: 'e',
    prefixHours: 'às',
    prefixMinutes: ':',
    prefixMinutesForHourPeriod: 'aos',
    suffixMinutesForHourPeriod: 'minuto(s)',
    errorInvalidCron: 'Expressão cron inválida',
    clearButtonText: 'Limpar',
    weekDays: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
    ],
    months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ],
    altWeekDays: [
        'DOM',
        'SEG',
        'TER',
        'QUA',
        'QUI',
        'SEX',
        'SÁB',
    ],
    altMonths: [
        'JAN',
        'FEV',
        'MAR',
        'ABR',
        'MAI',
        'JUN',
        'JUL',
        'AGO',
        'SET',
        'OUT',
        'NOV',
        'DEZ',
    ],
};