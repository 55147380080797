import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import api from '../../../services/api';
import {ApiSelectProps} from '../ApiSelect';

const ApiSelect = (props: ApiSelectProps) => {

    const {
        endpoint,
        error,
        getOptionLabel,
        helperText,
        label,
        multiple,
        onChange,
        readOnly,
        required,
        value,
    } = props;

    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const typingTimeoutRef = useRef<any>(null);

    const [loading, setLoading] = useState(false);

    const fetchOptions = () => {
        if (!endpoint) {
            return;
        }

        api.get(endpoint, {
            params: {search: searchText},
        }).then(res => {
            setOptions(res.data.results);
            setLoading(false);
        });
    };

    useEffect(() => {

        if (value && getOptionLabel(value) === searchText) {
            setLoading(false);
            return;
        }

        setLoading(true);

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(fetchOptions, 750);

        return () => {
            clearTimeout(typingTimeoutRef.current);
        };

    }, [searchText]);

    useEffect(() => {
        fetchOptions();
    }, [endpoint]);

    const handleInputChange = (event: SyntheticEvent, newSearchText: string) => {
        setSearchText(newSearchText);
    };

    return (<Autocomplete
        multiple={multiple}
        filterOptions={(x) => x}

        openText={'Abrir'}
        closeText={'Fechar'}
        clearText={'Remover'}
        loadingText={'Carregando...'}
        noOptionsText={searchText !== '' ? 'Sem opções para o filtro.' : 'Pesquisar...'}

        loading={loading}

        options={options}
        isOptionEqualToValue={(option: Record<string, any>, val: Record<string, any> | null) => (
            !!val && !!val.id && option.id.toString() === val.id.toString()
        )}
        getOptionLabel={option => getOptionLabel(option)}

        value={value}
        onInputChange={handleInputChange}
        onChange={(event, val) => onChange(event, val)}
        disabled={readOnly}

        renderInput={(params) => (<TextField
            {...params}
            label={label}
            variant={'outlined'}
            required={required}
            error={error}
            helperText={helperText}
            InputProps={{
                ...params.InputProps,
                endAdornment: (<React.Fragment>
                    {loading ? <CircularProgress color={'inherit'} size={20}/> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>),
            }}
        />)}
    />);
};

export default ApiSelect;
