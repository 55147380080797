import React, {Dispatch, SetStateAction} from 'react';

import CrudApiSelect from '../../../../components/crud/fields/CrudApiSelect';

import {Template} from '../Template.d';


const DatabaseField = (
    values: Template,
    setValues: Dispatch<SetStateAction<Template>>,
    errors: Record<string, string>,
    readOnly: (e: Template) => boolean,
) => {
    if (!values?.is_external_database) {
        return null;
    }

    return (
        <CrudApiSelect
            key={'database_id'}
            column={{
                accessorKey: 'queryset.database.description',
                header: 'Base de Dados',
                field: {
                    type: 'search',
                    labelKey: 'description',
                    name: 'queryset.database',
                    endpoint: '/api/v0/databases/',
                },
            }}
            values={values}
            setValues={setValues}
            errors={errors}
        />
    );
};

export default DatabaseField;
