import {Route, Routes} from './appRoutes.d';

const AppRoutes = {


    // auth
    Login: {
        path: '/entrar',
    },
    ResetPassword: {
        path: '/redefinir-senha/:token',
    },

    // user
    UserSettings: {
        path: '/perfil',
        allowAny: true,
    },

    // admin
    Users: {
        path: '/usuarios',
        module: 'users',
        object: 'user',
    },

    // templates
    Databases: {
        path: '/mensageria/bases',
        module: 'databases',
        object: 'database',
    },
    Templates: {
        path: '/mensageria/templates',
        module: 'messaging',
        object: 'template',
    },
    Instances: {
        path: '/mensageria/instancias',
        module: 'integration',
        object: 'instance',
    },
    Messages: {
        path: '/mensageria/mensagens',
        module: 'messaging',
        object: 'message',
    },

    // datasets
    Sources: {
        path: '/datasets/fontes',
        module: 'datasets',
        object: 'source',
    },
    Tables: {
        path: '/datasets/tabelas',
        module: 'datasets',
        object: 'table',
    }

} as Routes;

export const getPermissionLabel = (route: Route, action: string) => {
    if (route.permissions) {
        if (route.permissions[action]) {
            return route.permissions[action];
        }
    }

    if (route.module && route.object) {
        return `${route.module}.${action}_${route.object}`;
    }

    return null;
};

export default AppRoutes;