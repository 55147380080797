import React, {useEffect, useState} from 'react';

import {useSnackbar} from 'notistack';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import {DataGrid} from '@mui/x-data-grid';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import RetryIcon from '@mui/icons-material/Replay';
import WarningIcon from '@mui/icons-material/WarningRounded';

import api from '../../../../services/api';

import {QueryModalProps} from '../QueryModal';

const QueryModal = (props: QueryModalProps) => {

    const {

        // CONTROL
        open,
        setOpen,
        entity,
        endpoint,

        // STRUCTURE
        title,

    } = props;

    const {enqueueSnackbar} = useSnackbar();

    /**
     * RESULT LOAD
     */
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    const fetchResults = () => {
        setLoading(true);
        api.get(endpoint).then((res) => {
            setResults(
                res.data.results.map((result: any, index: number) => ({
                    id: index + 1,
                    ...result,
                })),
            );
            setError(false);
        }).catch((error) => {
            setError(true);
            enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao buscar dados!', {variant: 'error'});
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (open) {
            fetchResults();
        }
    }, [open, entity]);

    useEffect(() => {
        if (results.length > 0) {
            setColumns(
                Object.keys(results[0]).map((key) => ({
                    field: key,
                    headerName: key,
                    width: ((results[0][key] ?? '').length * 10) + 50,
                })),
            );
        }
    }, [results]);


    const Table = () => {
        return (
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <DataGrid
                    rows={results}
                    columns={columns}
                />
            </Box>
        );
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog
            open={open}
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle>
                {title}
                <Tooltip
                    title={'Fechar'}
                    arrow
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Tooltip>
            </DialogTitle>
            <DialogContent
                sx={{
                    height: '75vh',
                }}
            >
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        height: '100%',
                    }}
                >
                    {loading ? (
                        <CircularProgress/>
                    ) : (
                        error ? (
                            <>
                                <WarningIcon color={'error'}/>
                                <Typography>Erro ao executar consulta.</Typography>
                                <IconButton
                                    onClick={() => fetchResults()}
                                >
                                    <RetryIcon/>
                                </IconButton>
                            </>
                        ) : <Table/>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default QueryModal;