import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppRoutes from '../../../routes/appRoutes';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import {Column} from '../../../components/crud/ReportPage';
import {Message} from './index';
import ReportPage from '../../../components/crud/ReportPage/ReportPage';


const columns: Column<Message>[] = [
    {
        accessorKey: 'template.name',
        header: 'Template',
    },
    {
        accessorKey: 'integration_id',
        header: 'ID Integração',
    },
    {
        accessorKey: 'phone_number',
        header: 'Telefone',
    },
    {
        accessorKey: 'send_date',
        header: 'Data de Envio',
        type: 'datetime',
        enableColumnFilter: false,
    },
];


const name = {
    singular: 'Mensagem Enviada',
    plural: 'Mensagens Enviadas',
};


const MessagesPage = () => {

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{pt: 4}}
            >
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Mensageria', name.singular]}/>
                </Box>
            </Stack>

            <ReportPage
                name={name}
                endpoint={'/api/v0/messaging/messages/sended/'}
                columns={columns}
            />

        </>
    );
};

export default MessagesPage;