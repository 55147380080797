import React, {useState} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import QrCodeIcon from '@mui/icons-material/QrCode';
import ConnectedIcon from '@mui/icons-material/CheckCircle';
import DisconnectedIcon from '@mui/icons-material/Cancel';

import AppRoutes from '../../../routes/appRoutes';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CrudAPI, {Column} from '../../../components/crud/CrudPage';
import {Instance} from './Instance.d';

import QrcodeModal from './components/QrcodeModal';
import InstanceIdField from './components/InstanceIdField';
import ClientTokenField from './components/ClientTokenField';
import ChannelIdField from './components/ChanneldField';
import DomainField from './components/DomainField';
import TypeField, {INSTANCE_TYPES} from './components/TypeField';


const name = {
    singular: 'Instância',
    plural: 'Instâncias',
};

const columns: Column<Instance>[] = [
    {
        accessorKey: 'name',
        header: 'Nome',
    },
    {
        accessorKey: 'type',
        header: 'Tipo',
        field: {
            type: 'custom',
            component: TypeField,
        },
        Cell: ({row}) => INSTANCE_TYPES[row.original.type]
    },
    {
        accessorKey: 'status.connected',
        header: 'Conectado',
        enableEditing: false,
        Cell: ({row}) => {
            const status = 'status' in row.original ? (row.original.status?.connected ?? false) : true;
            return status ? <ConnectedIcon color={'success'}/> : <DisconnectedIcon color={'error'}/>
        }
    },
    {
        accessorKey: 'instance_token',
        header: 'Token',
        hideColumn: true,
    },
    {
        accessorKey: 'instance_id',
        header: 'ID da Instância',
        hideColumn: true,
        field: {
            type: 'custom',
            component: InstanceIdField,
        }
    },
    {
        accessorKey: 'client_token',
        header: 'Token do Cliente',
        hideColumn: true,
        field: {
            type: 'custom',
            component: ClientTokenField,
        }
    },
    {
        accessorKey: 'channel_id',
        header: 'ID do Canal',
        hideColumn: true,
        field: {
            type: 'custom',
            component: ChannelIdField,
        }
    },
    {
        accessorKey: 'domain',
        header: 'Domínio',
        hideColumn: true,
        field: {
            type: 'custom',
            component: DomainField,
        }
    }
];


const InstancesPage = () => {

    const [qrcodeOpen, setQrcodeOpen] = useState(false);
    const [modalInstanceId, setModalInstanceId] = useState<number | null>(null);
    const [refresh, setRefresh] = useState(false);

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{pt: 4}}
            >
                <Box sx={{mb: 1}}>
                    <Breadcrumb items={['Mensageria', name.singular]}/>
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Instances}
                endpoint={'/api/v0/integration/instances/'}
                columns={columns}

                // Table Control
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}

                // Actions
                extraRowActions={[
                    {
                        icon: QrCodeIcon,
                        tooltip: 'QR Code',
                        color: 'info',
                        onClick: (instance: Instance) => {
                            setModalInstanceId(instance.id);
                            setQrcodeOpen(true);
                        },
                        getShow: (entity: Instance) => entity.type === 'instancezapi',
                    },
                ]}
            />

            {modalInstanceId && (
                <QrcodeModal
                    open={qrcodeOpen}
                    setOpen={(open: boolean) => {
                        if (!open) {
                            setRefresh(v => !v);
                        }
                        setQrcodeOpen(open);
                    }}
                    instanceId={modalInstanceId}
                />
            )}
        </>
    );
};

export default InstancesPage;