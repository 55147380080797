import React, {SyntheticEvent} from 'react';

import ApiSelect from '../../../fields/ApiSelect';
import {CrudApiSelectProps} from '../CrudApiSelect';
import {TData} from '../../CrudPage';

import getNestedValue from '../../../utils/getNestedValue';
import getOptionLabel from '../../../utils/getOptionLabel';
import setNestedValue from '../../../utils/setNestedValue';


const CrudApiSelect = <T extends TData>(props: CrudApiSelectProps<T>) => {

    const {
        column,
        values,
        setValues,
        errors,
        readOnly = false,
        required = false,
    } = props;

    const {
        field,
        header,
    } = column;

    const {
        labelKey,
        name,
        endpoint,
        multiple = false,
        default: defaultVal,
    } = field;

    return (<ApiSelect
        label={header}
        endpoint={endpoint}
        value={getNestedValue(values, name, defaultVal)}
        onChange={(event: SyntheticEvent, val: string) => {
            setValues(
                setNestedValue(values, column.field.name, val),
            );
        }}
        multiple={multiple}
        getOptionLabel={option => getOptionLabel(option, labelKey)}
        error={errors?.hasOwnProperty(name) ?? false}
        helperText={errors ? (errors[name] ?? '') : ''}
        readOnly={readOnly}
        required={required}
    />);
};

export default CrudApiSelect;