import React, {ChangeEvent} from 'react';

import TextField from '@mui/material/TextField';

import {CrudTextProps} from '../CrudText';
import {TData} from '../../CrudPage';

import getNestedValue from '../../../utils/getNestedValue';
import setNestedValue from '../../../utils/setNestedValue';
import hasNestedValue from '../../../utils/hasNestedValue';

const CrudText = <T extends TData>(props: CrudTextProps<T>) => {

    const {
        column,
        values,
        setValues,
        errors,
        readOnly = false,
        required = false,
    } = props;

    const {
        accessorKey,
        header,
        field,
    } = column;

    return (<TextField
        fullWidth
        type={field?.type ?? 'text'}
        label={header}
        multiline={field && 'multiline' in field && field.multiline}
        disabled={readOnly}
        required={required}
        value={getNestedValue(values, accessorKey, '')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValues(
                setNestedValue(values, accessorKey, e.target.value),
            );
        }}
        error={hasNestedValue(errors, accessorKey)}
        helperText={errors ? getNestedValue(errors, accessorKey, '') : ''}
        InputProps={field?.InputProps}
        inputProps={{
            ...(field && 'step' in field && {step: field?.step}),
        }}
    />);
};

export default CrudText;