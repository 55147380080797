import React, {useEffect, useState} from 'react';

import {useSnackbar} from 'notistack';

import api from '../../../../services/api';

import CrudModal from '../../CrudModal';
import {CreateUpdateModalProps} from '../CreateUpdateModal';
import {TData} from '../../CrudPage';


const CreateUpdateModal = <T extends TData>(props: CreateUpdateModalProps<T>) => {

    const {
        // STRUCTURE
        name,
        endpoint,
        columns,

        // MODAL CONTROL
        createUpdateOpen,
        setCreateUpdateOpen,

        // ENTITY CONTROL
        modalEntity,
        setModalEntity,

        // ACTIONS
        refetch,
        formatPayload,
        getIsReadOnly,
        extraModalActions,

        // VISUAL
        modalWidth,

    } = props;

    const {enqueueSnackbar} = useSnackbar();

    /**
     * CREATE/UPDATE MODAL
     */
    const [createUpdateModalErrors, setCreateUpdateModalErrors] = useState({});

    // Remove errors from modal when the entity changes
    useEffect(() => {
        setCreateUpdateModalErrors({});
    }, [modalEntity]);

    const concatFieldErrors = (fieldErrors: Record<string, any>) => {
        let errors = {} as Record<string, any>;

        for (let [key, val] of Object.entries(fieldErrors)) {
            if (Array.isArray(val)) {
                errors[key] = val.join(',');
            } else if (typeof val === 'object') {
                errors[key] = concatFieldErrors(val);
            }
        }

        return errors;
    };

    const getFirstFieldError = (fieldErrors: Record<string, any>): string => {
        const firstError = Object.values(fieldErrors)[0];

        if (typeof firstError === 'string') {
            return firstError;
        }

        return getFirstFieldError(fieldErrors[0]);
    };

    const handleSubmitError = (error: any) => {
        try {
            let detail = null;
            let errors = error.response.data as Record<string, string[]>;

            if (error.response.data.hasOwnProperty('detail')) {
                detail = errors.detail;
                delete errors.detail;
            }

            let fieldErrors = concatFieldErrors(errors);

            setCreateUpdateModalErrors(fieldErrors);

            if (detail) {
                enqueueSnackbar(detail, {variant: 'error'});
            } else {
                enqueueSnackbar(getFirstFieldError(fieldErrors), {variant: 'error'});
            }
        } catch (e) {
            enqueueSnackbar('Erro ao cadastrar!', {variant: 'error'});
        }
    };

    const handleSubmitSuccess = () => {
        setCreateUpdateOpen(false);
        setCreateUpdateModalErrors({});
        setModalEntity(null);
        refetch();
    };

    const handleCreateUpdateSubmit = async (formData: FormData, entity_id: number, values: T) => {
        if (formatPayload) {
            formData = formatPayload(formData, entity_id, values);
        }

        if (entity_id) {
            await api.put(`${endpoint}${entity_id}/`, formData).then(handleSubmitSuccess).catch(handleSubmitError);
        } else {
            await api.post(`${endpoint}`, formData).then(handleSubmitSuccess).catch(handleSubmitError);
        }
    };

    const handleCreateUpdateCancel = () => {
        setCreateUpdateOpen(false);
        setModalEntity(undefined);
    };

    return (
        <CrudModal
            // States
            open={createUpdateOpen}
            entity={modalEntity}
            errors={createUpdateModalErrors}
            readOnly={(modalEntity && getIsReadOnly) ? getIsReadOnly(modalEntity) : false}

            // Structure
            columns={columns}

            // Actions
            extraModalActions={extraModalActions}

            // Texts
            readTitle={`Visualizar ${name.singular}`}
            createTitle={`Criar ${name.singular}`}
            updateTitle={`Editar ${name.singular}`}

            // Events
            onSubmit={handleCreateUpdateSubmit}
            onCancel={handleCreateUpdateCancel}

            // Visual
            modalWidth={modalWidth}
        />
    );
};

export default CreateUpdateModal;