import React, {useState} from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import CreateUpdateModal from '../modals/CreateUpdateModal';
import DeleteModal from '../modals/DeleteModal';

import {CrudProps} from '../Crud';
import {Column, TData} from '../CrudPage';
import CrudTable from '../CrudTable';

const Crud = <T extends TData>(props: CrudProps<T>) => {

    const {
        // STRUCTURE
        name,
        columns,
        endpoint,

        // ACTIONS
        rowActions,
        extraRowActions,
        extraModalActions,
        handleCreateUpdate,
        rowDoubleClick,
        getIsReadOnly,

        // MODULES
        enableUpdate = false,
        enableCreate = false,
        enableDelete = false,

        // API CONTROL
        formatPayload,

        // TABLE CONTROL
        controlledRefresh,
        setControlledRefresh,

        // MODAL CONTROL
        modalEntity,
        setModalEntity,
        createUpdateOpen = false,
        setCreateUpdateOpen,

        // MODAL VISUAL
        modalWidth,

    } = props;

    /**
     * TABLE CONTROL
     */
    const [uncontrolledRefresh, setUncontrolledRefresh] = useState(false);
    const refresh = controlledRefresh ? controlledRefresh : uncontrolledRefresh;
    const setRefresh = controlledRefresh ? setControlledRefresh : setUncontrolledRefresh;

    /**
     * DELETE MODAL CONTROL
     */
    const [deleteOpen, setDeleteOpen] = useState(false);

    const openDeleteModal = (entity: T) => {
        setModalEntity(entity);
        setDeleteOpen(true);
    };

    /**
     * COLUMN DEFINITION
     */
    const crudColumns = columns.map((col: Column<T>): Column<T> => {
        if (col.accessorKey === 'id') {
            return {
                ...col,
                enableEditing: false,
                enableSorting: false,
                enableColumnActions: false,
                size: 10,
                Cell: ({row}) => row.original[col.accessorKey].padStart(5, '0'),
            };
        }
        if (col.type === 'check') {
            return {
                enableEditing: true,
                Cell: ({row}) => row.original[col.accessorKey] ? <CheckIcon/> : <CircleIcon/>,
                ...col,
            };
        }
        if (col.type === 'date') {
            return {
                size: 125,
                enableEditing: true,
                Cell: ({row}) => row.original[col.accessorKey].split('-').reverse().join('/'),
                ...col,
            };
        }
        if (col.type === 'datetime') {
            return {
                enableEditing: true,
                Cell: ({row}) => {
                    const value = row.original[col.accessorKey];
                    if (!value) {
                        return '';
                    }

                    let [date, hour] = value.split('T');
                    date = date.split('-').reverse().join('/');
                    hour = hour.split('.')[0].split('Z')[0];
                    return `${date} ${hour}`;
                }, ...col,
            };
        }
        return {
            enableEditing: true, ...col,
        };
    });

    /**
     * COMPONENT RENDER
     */
    return (<>
        {/** TABLE */}
        <CrudTable
            // STRUCTURE
            columns={crudColumns}
            endpoint={endpoint}

            // ACTIONS
            refresh={refresh}
            rowActions={rowActions}
            extraRowActions={[
                ...extraRowActions ?? [],
                ...(enableDelete ? [
                    {
                        onClick: openDeleteModal,
                        tooltip: 'Apagar',
                        icon: DeleteIcon,
                        color: 'error',
                    },
                ] : []),
            ]}
            handleCreateUpdate={handleCreateUpdate}
            rowDoubleClick={rowDoubleClick}
            getIsReadOnly={getIsReadOnly}

            // PERMISSIONS
            enableUpdate={enableUpdate}
        />

        {/** CREATE/UPDATE MODAL */}
        <CreateUpdateModal
            // STRUCTURE
            name={name}
            endpoint={endpoint}
            columns={crudColumns}

            // MODAL CONTROL
            createUpdateOpen={createUpdateOpen}
            setCreateUpdateOpen={setCreateUpdateOpen}

            // ENTITY CONTROL
            modalEntity={modalEntity}
            setModalEntity={setModalEntity}

            // ACTIONS
            refetch={() => setRefresh((v: boolean) => !v)}
            formatPayload={formatPayload}
            getIsReadOnly={getIsReadOnly}
            extraModalActions={extraModalActions}

            // VISUAL
            modalWidth={modalWidth}
        />

        {/** DELETE CONFIRM */}
        <DeleteModal
            // STRUCTURE
            name={name}
            endpoint={endpoint}

            // MODAL CONTROL
            modalEntity={modalEntity}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}

            // ACTIONS
            refresh={() => setRefresh((v: boolean) => !v)}
        />
    </>);
};

export default Crud;