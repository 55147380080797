import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import {CrudHeaderProps} from '../CrudHeader';
import {TData} from '../CrudPage';

const CrudHeader = <T extends TData>(props: CrudHeaderProps<T>) => {

    const {
        // STRUCTURE
        name,

        // ACTIONS
        extraCrudActions,
        handleCreateUpdate,

        // PERMISSIONS
        enableCreate,
    } = props;

    return (
        <Stack
            justifyContent={'space-between'}
            direction={'row'}
            sx={{mb: 2}}
            flexWrap={'wrap'}
            gap={1}
        >
            <Typography
                variant={'h4'}
                sx={{
                    mb: 0,
                }}
            >
                {name.plural}
            </Typography>
            <Stack
                direction={'row'}
                flexWrap={'wrap'}
                gap={1}
            >
                {extraCrudActions ? extraCrudActions() : null}
                {enableCreate && handleCreateUpdate && <Button
                    onClick={() => handleCreateUpdate(null)}
                    sx={{m: 1}}
                >
                    <AddIcon/>
                    <Typography variant={'button'} sx={{ml: 1}}>
                        {name.singular}
                    </Typography>
                </Button>}
            </Stack>
        </Stack>
    );
};

export default CrudHeader;