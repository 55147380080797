import React, {useEffect, useState} from 'react';

import {useSnackbar} from 'notistack';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import ConnectedIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/WarningRounded';

import api from '../../../../../services/api';

import {QrcodeModalProps} from './index';


const QrcodeModal = (props: QrcodeModalProps) => {

    const {
        open,
        setOpen,
        instanceId,
    } = props;

    const {enqueueSnackbar} = useSnackbar();

    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [connected, setConnected] = useState(false);

    const reloadQrcode = () => {
        if (open && !connected) {
            setLoading(true);
            api.get(`/api/v0/integration/instances/${instanceId}/qr-code/`).then((res) => {
                setConnected(res.data.connected ?? false);
                setImgSrc(res.data.value);
            }).catch((error) => {
                setError(true);
                enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao buscar imagem!', {variant: 'error'});
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const disconnect = () => {
        if (open) {
            setLoading(true);
            api.post(`/api/v0/integration/instances/${instanceId}/disconnect/`).then((res) => {
                setConnected(false);
                setOpen(false);
                reloadQrcode();
            }).catch((error) => {
                enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao desconectar!', {variant: 'error'});
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        if (!imgSrc) {
            reloadQrcode();
        }

        const interval = setInterval(
            reloadQrcode,
            10000,
        );

        return () => clearInterval(interval);
    }, [open, instanceId]);

    const ImageComponent = () => {
        if (error) {
            return (
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <WarningIcon color={'error'}/>
                    <Typography>Erro ao carregar imagem!</Typography>
                </Stack>
            );
        }

        if (loading) {
            return (
                <CircularProgress/>
            );
        }

        if (connected) {
            return (
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        border: '1px solid',
                        borderColor: 'success.main',
                        borderRadius: 2,
                        height: 250,
                        width: 250,
                    }}
                >
                    <ConnectedIcon
                        color={'success'}
                        sx={{
                            fontSize: 100,
                        }}
                    />

                    <Link
                        onClick={disconnect}
                        fontFamily={'monospace'}
                        sx={{
                            cursor: 'pointer',
                        }}
                    >
                        Desconectar
                    </Link>
                </Stack>
            );
        }

        return (
            <img
                style={{
                    width: '250px',
                    height: 'auto',
                }}
                src={imgSrc}
                onError={() => setError(true)}
            />
        );
    };

    /**
     * COMPONENT RENDER
     */
    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle textAlign={'center'}>
                <Tooltip
                    title={'Fechar'}
                    arrow
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Tooltip>
            </DialogTitle>
            <DialogContent>
                <Stack
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    spacing={1}
                    sx={{
                        minHeight: 400,
                    }}
                >
                    <Box>
                        <Typography
                            variant={'h5'}
                            textAlign={'center'}
                            fontWeight={'bold'}
                            sx={{
                                mb: 2,
                            }}
                        >
                            {connected ?
                                'Conectado!' :
                                'Leia o QRCode!'
                            }
                        </Typography>
                        <Typography
                            variant={'body1'}
                            textAlign={'center'}
                        >
                            {connected ?
                                'Sua instância está conectada, você já pode enviar mensagens via API.' :
                                'Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a esta instância.'
                            }
                        </Typography>
                    </Box>

                    <ImageComponent/>

                    <Box/>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default QrcodeModal;