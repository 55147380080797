import React, {Dispatch, SetStateAction} from 'react';

import CrudText from '../../../../components/crud/fields/CrudText';

import {ZapiInstance} from '../Instance.d';


const ClientTokenField = (
    values: ZapiInstance,
    setValues: Dispatch<SetStateAction<ZapiInstance>>,
    errors: Record<string, string>,
    readOnly: (e: ZapiInstance) => boolean,
) => {

    if (values?.type !== 'instancezapi') {
        return null;
    }

    return (
        <CrudText
            key={'client_token'}
            column={{
                accessorKey: 'client_token',
                header: 'Token do Cliente',
            }}
            values={values}
            setValues={setValues}
            errors={errors}
        />
    );
};

export default ClientTokenField;
