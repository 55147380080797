import React, {useEffect, useState} from 'react';

import styled from '@mui/material/styles/styled';

import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import Preloader from '../../../components/Preloader';
import Sidebar from '../../../components/sidebar/Sidebar';

import {RouteWithSidebarProps} from '../RouteWithSidebar';


const Main = styled('main')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        marginLeft: '260px',
    },
    overflow: 'visible',
    padding: '0px 15px 0px 15px',
    margin: '0px',
}));

export default function RouteWithSidebar(props: RouteWithSidebarProps) {

    const {component: Component, fullFrame, ...rest} = props;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (<>
        <Preloader show={!loaded}/>
        <Sidebar/>

        <Main
            sx={{
                height: fullFrame ? '99.5vh' : '',
                p: fullFrame ? 0 : '',
            }}
        >
            {!fullFrame && <NavBar/>}
            <Component {...rest} />
            {!fullFrame && <Footer/>}
        </Main>
    </>);
};