import React from 'react';

import {useNavigate} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {CrudTableBtnProps} from '../CrudTableBtn';


const CrudTableBtn = (props: CrudTableBtnProps) => {

    const {
        tooltip,
        color,
        entity,
        onClick,
        link,
        icon: IconComponent,
    } = props;

    const navigate = useNavigate();

    /**
     * EVENT HANDLERS
     */
    const handleClick = () => {
        if (onClick) {
            onClick(entity);
        } else if (link) {
            navigate(link);
        }
    };

    /**
     * RENDER COMPONENT
     */
    return (<Tooltip arrow placement={'top'} title={tooltip}>
        <IconButton
            onClick={handleClick}
            sx={{
                mx: 0.2,
                p: 0.2,
                border: '2px solid',
                borderRadius: 1,
            }}
        >
            <IconComponent
                color={color}
                fontSize={'small'}
            />
        </IconButton>
    </Tooltip>);
};

export default CrudTableBtn;