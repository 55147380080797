/**
 * STORE USER AUTH ON LOCALSTORAGE
 */

/**
 * Identifier for the auth token on the localstorage
 * @type {string}
 */
export const TOKEN_KEY = '@zapzoom-token';

/**
 * Identify if user is authenticated (has token on localstorage)
 * @returns {boolean} - Returns true if the user is authenticated, false otherwise
 */
export const isAuthenticated = () => {
    return localStorage.getItem(TOKEN_KEY) !== null;
};

/**
 * Set the auth token in localstorage
 * @param {string} token - The auth token to be stored in localstorage
 */
export const setToken = (token: string) => {
    return localStorage.setItem(TOKEN_KEY, token);
};

/**
 * Get the auth token from localstorage
 * @returns {string | null} - Returns the auth token if present in localstorage, null otherwise
 */
export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
};

/**
 * Remove the auth token from localstorage
 */
export const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
};