import React, {Dispatch, SetStateAction} from 'react';

import {TemplateFromParameters} from '../Template.d';
import CrudText from '../../../../components/crud/fields/CrudText';


const TemplateIdField = (
    values: TemplateFromParameters,
    setValues: Dispatch<SetStateAction<TemplateFromParameters>>,
    errors: Record<string, string>,
    readOnly: (e: TemplateFromParameters) => boolean,
) => {
    if (values.type !== 'templatefromparameters') {
        return null;
    }

    return (
        <CrudText
            key={'template_id'}
            column={{
                accessorKey: 'template_id',
                header: 'Id do Template',
                hideColumn: true,
                field: {
                    type: 'text',
                },
            }}
            values={values}
            setValues={setValues}
            errors={errors}
        />
    );
};

export default TemplateIdField;
